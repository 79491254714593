@tailwind base;
@tailwind components;
@tailwind utilities;

.grid-rows-3 {
  grid-template-rows: 1fr 2.5fr 0.5fr;
}

.no-transform-anim {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.custom-card-image .card.image-full:before {
  border-radius: 0.5rem;
  opacity: 0;
}

body {
  height: 100vh;
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}

.bg-animated {
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  /* height: 100vh; */
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/*iMessage */
html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: #44d6b1;
  background-image: url(https://en.tutsps.com/images/create-a-custom-ios-11-style-blur-background-in-photoshop/create-a-custom-ios-11-style-blur-background-in-photoshop-012.jpg);
  background-size: cover;
  overflow: hidden;
  font-family: 'Open Sans', sans-serif;
}

.im h1 {
  font-weight: 100;
  color: white;
  font-size: 1.5em;
}

#page-container {
  width: 100%;
  height: 100%;
}

#dialog-container {
  /** border: dashed 2px pink; **/
  width: 350px;
  min-height: 300px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
}

.dialog-box {
  position: relative;
  min-height: 80px;
  max-height: 180px;
  border-radius: 10px;
  margin-bottom: 8px;
  overflow: hidden;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.06);
}

.dialog-box .header {
  height: 25px;
  position: relative;
  color: black;
}

.background-blur {
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.35);
  position: absolute;
}

.left {
  float: left;
  text-align: left;
  width: content-max;
  padding: 3px 0 0 10px;
}

.right {
  float: right;
  text-align: right;
  width: content-max;
  padding: 3px 10px 0 0;
}

.contents {
  z-index: 100;
  position: inherit;
  font-size: 0.8em;
  color: black;
}

.main-content {
  padding: 8px 20px 10px 10px;
  height: content-max;
}

#footer-text {
  font-size: 0.8em;
  position: absolute;
  bottom: 8px;
  right: 8px;
}
